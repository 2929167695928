.deliveryNote {
    padding: 1px 1px;
    margin: 0 auto;
    width: 100%;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    background-color: #e2e2e5;
    margin-bottom: 3px;
    border: 2px solid #a6a6a7;
}

ul {
    padding: 0;
}

li {
    margin: 0 auto;
    list-style-type: none;
}

.dropdown-calender {
    margin-bottom: 10px;
    display: flex;
    align-content: flex-start;
}

.datepicker-button {
    width: 50px !important;
}


/* Datepicker */

.react-datepicker-wrapper input {
    height: 48px !important;
    width: 100px;
    text-align: center;
}

.react-datepicker-wrapper {
    width: auto !important;
}

.dropdown {
    width: 150px;
}

.button-end-aligned {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.red-color {
    background-color: #cc3c34 !important;
    border-color: #cc3c34 !important;
}


/* Check icon on delivery note */

.signed-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.signed-icon svg {
    font-size: 30px;
}

.logo {
    position: absolute;
    top: 0px;
    right: 0px;
}


/* Checkbox signed */

.MuiToggleButton-root {
    color: black !important;
}

.MuiToggleButton-root.Mui-selected {
    background-color: #cc3c34 !important;
    color: white !important;
}

.checkbox {
    background-color: #e2e2e5 !important;
    color: black !important;
}

.button-red-color {
    background-color: #cc3c34;
    border-color: #cc3c34;
}