.button-row {
    display: flex;
    align-content: center;
}

.popup {
    padding-top: 5px;
    padding-bottom: 5px;
    border: 5px solid black;
    background-color: rgb(240, 238, 238);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    height: 400px;
}

.popup-email {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 5px solid black;
    background-color: rgb(240, 238, 238);
    border-radius: 5px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.popup-email input {
    width: 100%;
    margin: 10px;
    text-align: center;
}

.signature-canvas {
    background-color: rgb(194, 191, 191);

    width: 100%;
    height: 75%;
}

.full-width-height {
    width: 100%;
    height: 100%;
}

.signature-buttons-div {
    padding-top: 5px;
}

.popup-buttons {
    width: 90px;
    margin: 5px;
}

.button-end-aligned {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.pdf-renderer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    margin-left: 0px;
    background-color: white;
}

.react-pdf__Page__canvas {
    width: 100% !important;
}

.button-height {
    height: 48px;
}
